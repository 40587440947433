import React from 'react';
import { graphql } from 'gatsby';
import Image from 'gatsby-image';
import chunk from 'lodash/chunk';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBusinessTime, faTools, faHandshake } from '@fortawesome/free-solid-svg-icons';

import Layout from '../components/layout/layout';
import HeaderExpansion from '../components/layout/header-expansion';
import Section from '../components/layout/section';
import FlexboxItem from '../components/layout/flexbox/flexbox--item';
import FlexboxTwoPart from '../components/layout/flexbox/flexbox--two-part';
import FlexboxThreePart from '../components/layout/flexbox/flexbox--three-part';

import { FixString } from '../functions/FixString';
import { GenerateSEO } from '../functions/GenerateSEO';

const About = ({ data: { allContentfulGrampusGAboutUs: { nodes: about } } }) => {
    const section3SubSections = chunk(about[0].section3.sections, 3);

    const businessIcon = <FontAwesomeIcon className="page__icon page__icon--large-margins" icon={ faBusinessTime } />;
    const toolsIcon = <FontAwesomeIcon className="page__icon page__icon--large-margins" icon={ faTools } />;
    const handshakeIcon = <FontAwesomeIcon className="page__icon page__icon--large-margins" icon={ faHandshake } />;

    return (
      <Layout 
        contactBanner={ true }
        location="about"
        metadata={ GenerateSEO({
          is404: false,
          lang: 'en',
          title: about[0].seo.seoTitle,
          description: about[0].seo.seoDescription,
          image: about[0].Images[0].localFile.childImageSharp.fixed.src,
          imageAlt: about[0].Images[0].description,
          url: 'https://www.grampusg.com/about/'
        })}>
        <HeaderExpansion tagline={ about[0].tagline } />
        <Section className="first">
          { about[0].section1.header ? <h2 className="section__header">{ about[0].section1.header }</h2> : '' }
          { about[0].section1.sections[0].header ? <h3 className="section__subheader">{ about[0].section1.sections[0].header }</h3> : '' }
          { about[0].section1.sections[0].content.content ? <p className="section__paragraph add-margin u-margin-bottom-large">{ FixString(about[0].section1.sections[0].content.content) }</p> : '' }
          <FlexboxTwoPart>
            <FlexboxItem>
              {
                about[0].section1.sections.filter((item, index) => index !== 0).map((paragraph, index) => (
                  <React.Fragment key={ index }>
                    { paragraph.header ? <h3 className="section__subheader">{ paragraph.header }</h3> : '' }
                    { paragraph.content.content ? <p className="section__paragraph u-margin-bottom-large"> { FixString(paragraph.content.content) }</p> : '' }
                  </React.Fragment>
                ))
              }
            </FlexboxItem>
            <FlexboxItem isImage>
              <Image className="section__image" fluid={ about[0].Images[0].localFile.childImageSharp.fluid } alt={ about[0].Images[0].description } />
            </FlexboxItem>
          </FlexboxTwoPart>
        </Section>
        <Section className="blue-background center">
            { about[0].section2.header ? <h2 className="section__header">{ about[0].section2.header }</h2> : '' }
            {
                about[0].section2.sections.map((paragraph, index) => (
                    <React.Fragment key={ index }>
                        { paragraph.header ? <h3 className="section__subheader">{ paragraph.header }</h3> : '' }
                        { paragraph.content.content ? <p className="section__paragraph add-margin">{ FixString(paragraph.content.content) }</p> : '' }
                    </React.Fragment>
                ))
            }
            { about[0].section3.header ? <h2 className="section__header">{ about[0].section3.header }</h2> : '' }
            {
                section3SubSections.map((itemContainer, outerIndex) => (
                    <FlexboxThreePart className="add-margin" key={ outerIndex }>
                        { 
                            itemContainer.map((item, innerIndex) => (
                                <FlexboxItem key={ innerIndex }>
                                    { innerIndex === 0 && outerIndex === 0 ? businessIcon : 
                                    innerIndex === 1 && outerIndex === 0 ? toolsIcon : 
                                    innerIndex === 2 && outerIndex === 0 ? handshakeIcon : '' }
                                    { item.header ? <h3 className="section__subheader">{ item.header }</h3> : '' }
                                    { item.content.content ? <p className="section__paragraph">{ FixString(item.content.content) }</p> : '' }
                                </FlexboxItem>
                            ))
                        }
                    </FlexboxThreePart>
                ))
            }
        </Section>
    </Layout>
  )
}

export const query = graphql`
query AboutUsQuery {
  allContentfulGrampusGAboutUs {
    nodes {
      seo {
        seoTitle
        seoDescription
      }
      Images {
        fixed {
          src
        }
        localFile {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
                fixed {
                    src
                }
            }
        }
        description
      }
      tagline
      section1 {
        header
        sections {
          header
          content {
            content
          }
        }
      }
      section2 {
        header
        sections {
          header
          content {
            content
          }
        }
      }
      section3 {
        header
        sections {
          header
          content {
            content
          }
        }
      }
    }
  }
}
`;


export default About;
